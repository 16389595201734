/* eslint-disable react/display-name */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import './style.scss';
import Heading from '../components/heading';
import Pricing from '../components/pricing';

const BlogPostTemplate = ({ data: { mdx } }) =>
{
    // const siteTitle = mdx.site.siteMetadata.title;
    // const { previous, next } = pageContext;
    const components = {
        Heading,
    };
    return (
        <Layout>
            <SEO title={mdx.frontmatter.title}/>
            <div className="container">
                <MDXProvider components={components}>
                    <MDXRenderer >{mdx.body}</MDXRenderer>
                </MDXProvider>
            </div>
        </Layout>
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`;

// export const pageQuery = graphql`
//   query BlogPostBySlug($slug: String!) {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     mdx(slug: { eq: $slug }) {
//       id
//       excerpt(pruneLength: 160)
//       body
//       frontmatter {
//         title
//         date(formatString: "MMMM DD, YYYY")
//         description
//       }
//     }
//   }
// `;
